import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: '[appDropdownKeyScroll]'
})

export class DropdownKeyScrollDirective {
  private lastPressedKey: string = '';
  private currentMatchIndex: number = 0;

  constructor(private el: ElementRef) { }

  @HostListener('keydown', ['$event'])
  onKeyPress(event: KeyboardEvent): void {
    const char = event.key.toLowerCase();
    const dropdownPanel = this.el.nativeElement.querySelector('.p-dropdown-items') as HTMLElement | null;

    if (!dropdownPanel) return;

    const items = Array.from(dropdownPanel.querySelectorAll('.p-dropdown-item')) as HTMLElement[];
    const matchingItems = items.filter(item =>
      item.textContent?.trim().toLowerCase().startsWith(char));

    if (matchingItems.length > 0) {
      this.currentMatchIndex =
        this.lastPressedKey === char ? (this.currentMatchIndex + 1) % matchingItems.length : 0;

      this.lastPressedKey = char;
      const selectedItem = matchingItems[this.currentMatchIndex];

      requestAnimationFrame(() => {
        selectedItem.scrollIntoView({ behavior: "smooth", block: "nearest" });
      });
    }
  }
}