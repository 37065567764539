
export const  ICEM_DESIGN_FILE = 'iCem Design File';
export const  ICEM_REAL_TIME_FILE = 'Realtime File';
export const  JOB_OBJECTIVES = 'Job Objectives';
export const  JOB_PROGRAM = 'Job Program';
export const  PUMP_SCHEDULE = 'Pump Schedule';
export const  THICKENING_TIME = 'Thickening Time';
export const  LOCAL_WELL_REVIEW = 'Local Well Review';
export const  CRITICAL_WELL_REVIEW = 'Critical Well Review';
export const  SALES_ORDER_AND_BOM_TYPE = 'Sales Order & BOM Type';
export const CRITICAL_COMPONENTS = 'Critical Components';
export const LOAD_OUT_VOLUMES = 'Load Out Volumes';
export const UPLOAD_HANDOVER = 'Upload Handover';
export const WELL_CONDITION_VERIFICATION = 'Well Condition Verification'
export const RECONCILIATION = 'Reconciliation'
export const KPI_DATA = 'KPI Data';
export const JSQA = 'JSQA';
export const EQUIPMENT_FUNCTION_TEST_DOCUMENT = 'Equipment Function Test Document'
export const JSA = 'JSA'
export const CASING_EQUIPMENT = 'Casing Equipment';
export const JOB_SURFACE_EQUIPMENT = "Surface Equipment";

export const  JOB_LOG = 'Job Log';

export const CP3_EXPORT_SPREADSHEET_NAME = 'CP3 JSQA REPORT.xlsx';
export const JOB_PLUGBASE_NOTRECOMENDEDVALUE = 1; // None value
export const JOB_PLUGBASE_DEFAULTVALUE = null; // default value
export const ENGINEERING_SOLUTIONS_MANUAL_CEMENT_PLUGS = 'https://halworld.corp.halliburton.com/content/dam/halworld/internal/hms/cementing/pubsdata/cmt/GD-GL-HAL-CMT-100E.docx?node-id=j1ezgvqq';

export enum ControlPointType {
  ControlPoint1 = 1,
  ControlPoint2 = 2,
  ControlPoint3 = 3,
  ControlPoint4 = 4
}

export enum EServiceType {
  CmtService,
  CeService,
  DceService,
  CeCmtService
}

export enum JobComplienceStatus {
  None = 0,
  DueSoon = 1,
  OutOfComplience = 2
}

export const ControlPointStatus = {
  InProgress: 'In Progress',
  Submitted: 'Submitted',
  Unlock: 'Unlock',
  Rejected: 'Rejected',
  Completed: 'Completed',
  Prepared: 'Prepared',
  Approved: 'Approved'
};

export const ControlPointTabs = {
  JOB_HEADER: 'JOB_HEADER',
  ICEM_FILE: 'ICEM_FILE',
  ICEM_REALTIME_FILE: 'ICEM_REALTIME_FILE',
  CDI: 'CDI',
  JOB_OBJECTIVE: 'JOB_OBJECTIVE',
  PUMP_SCHEDULE: 'PUMP_SCHEDULE',
  JOB_PROGRAM: 'JOB_PROGRAM',
  EQUIPMENT_VERIFICATION: 'EQUIPMENT_VERIFICATION',
  HANDOVER: 'HANDOVER',
  CALL_SHEET: 'CALL_SHEET',
  LOCAL_WELL_REVIEW: 'LOCAL_WELL_REVIEW',
  CP1_NOTE: 'CP1_NOTE',
  RIG_DETAILS: 'RIG_DETAILS',
  OTHER_SPECIFICATION_NOTE: 'OTHER_SPECIFICATION_NOTE',
  SUBMISSION_REJECTION_COMMENTS: 'SUBMISSION_REJECTION_COMMENTS',
  CASING_EQUIPMENT: 'CASING_EQUIPMENT',
  RISK_ASSESSMENT: 'RISK_ASSESSMENT',
  JOB_SITE_QUALITY: 'JOB_SITE_QUALITY',
  EQUIPMENT_FUNCTION: 'EQUIPMENT_FUNCTION',
  JOB_SAFETY_ANALYSIS: 'JOB_SAFETY_ANALYSIS',
  EQUIPMENT: 'EQUIPMENT',
  CONTROL_SPREADSHEET: 'CONTROL_SPREADSHEET',
  POST_JOB_REPORT: 'POST_JOB_REPORT',
  ADDITIONAL_COMMENTS: 'ADDITIONAL_COMMENTS',
  CRITICAL_WELL_REVIEW: 'CRITICAL_WELL_REVIEW',
  WELL_CONDITION_VERIFICATION: 'WELL_CONDITION_VERIFICATION',
  JOB_LOG: 'JOB_LOG',
  SIDE_ITEMS: 'SIDE_ITEMS',
  LESSONS_LEARNED: 'LESSONS_LEARNED',
  JOB_SURFACE_EQUIPMENT: 'JOB_SURFACE_EQUIPMENT',
  BARRIER_ADVISOR: 'BARRIER_ADVISOR',
  BULK_PLANT: 'BULK_PLANT',
  CEMENT_TAILORING: 'CEMENT_TAILORING',
  SPACER_TAILORING: 'SPACER_TAILORING'
};

export const ControlPointList = [
  {
    // control point 1
    type: ControlPointType.ControlPoint1,
    title: 'Design Review',
    // items belong to each control point
    items: [
      ControlPointTabs.JOB_HEADER,
      ControlPointTabs.ICEM_FILE,
      ControlPointTabs.JOB_OBJECTIVE,
      ControlPointTabs.CDI,
      ControlPointTabs.BARRIER_ADVISOR,
      ControlPointTabs.JOB_PROGRAM,
      ControlPointTabs.PUMP_SCHEDULE,
      ControlPointTabs.LOCAL_WELL_REVIEW,
      ControlPointTabs.OTHER_SPECIFICATION_NOTE,
      ControlPointTabs.SUBMISSION_REJECTION_COMMENTS,
      ControlPointTabs.CASING_EQUIPMENT,
      ControlPointTabs.RISK_ASSESSMENT,
      ControlPointTabs.CRITICAL_WELL_REVIEW,
      ControlPointTabs.LESSONS_LEARNED,
      ControlPointTabs.JOB_SURFACE_EQUIPMENT,
      ControlPointTabs.BULK_PLANT,
      ControlPointTabs.CEMENT_TAILORING,
      ControlPointTabs.SPACER_TAILORING
    ],
    // items displayed in control points of job dashboard
    visibleItems: [
      { name: ICEM_DESIGN_FILE, checked: false, visibled: false , isCMTService: true, isCEService: false, isDCEService: false },
      { name: JOB_OBJECTIVES, checked: false, visibled: true , isCMTService: true, isCEService: true, isDCEService: false },
      { name: JOB_PROGRAM, checked: false, visibled: false , isCMTService: true, isCEService: true, isDCEService: true },
      { name: PUMP_SCHEDULE, checked: false, visibled: true , isCMTService: true, isCEService: false, isDCEService: false },
      { name: LOCAL_WELL_REVIEW, checked: false, visibled: false, isCMTService: true, isCEService: true, isDCEService: true },
      { name: CRITICAL_WELL_REVIEW, checked: false, visibled: false, isCMTService: true, isCEService: true, isDCEService: true },
      { name: JOB_SURFACE_EQUIPMENT, checked: false, visibled: true, isCMTService: true, isCEService: false, isDCEService: false }
    ],
    secondaryItems: [
      { name: CASING_EQUIPMENT, checked: false, visibled: true , isCMTService: false, isCECMTService: true, isCEService: true, isDCEService: true },
    ]
  },
  {
    // control point 2
    type: ControlPointType.ControlPoint2,
    title: 'Loadout Confirmation',
    items: [
      ControlPointTabs.JOB_HEADER,
      ControlPointTabs.PUMP_SCHEDULE,
      ControlPointTabs.SIDE_ITEMS,
      ControlPointTabs.CALL_SHEET,
      ControlPointTabs.HANDOVER,
      ControlPointTabs.JOB_SURFACE_EQUIPMENT,
      ControlPointTabs.EQUIPMENT_VERIFICATION,
      ControlPointTabs.RIG_DETAILS,
      ControlPointTabs.CP1_NOTE,
      ControlPointTabs.OTHER_SPECIFICATION_NOTE,
      ControlPointTabs.EQUIPMENT,
      ControlPointTabs.WELL_CONDITION_VERIFICATION,
      ControlPointTabs.LESSONS_LEARNED,
      ControlPointTabs.CASING_EQUIPMENT,
    ],
    visibleItems: [
      { name: LOAD_OUT_VOLUMES, checked: false, visibled: true , isCMTService: true, isCEService: false, isDCEService: false },
      { name: CRITICAL_COMPONENTS, checked: false, visibled: true , isCMTService: true, isCEService: false, isDCEService: false },
      { name: WELL_CONDITION_VERIFICATION, checked: false, visibled: true , isCMTService: true, isCEService: true, isDCEService: false },
      { name: UPLOAD_HANDOVER, checked: false, visibled: true , isCMTService: true, isCEService: true, isDCEService: false },
    ],
    secondaryItems: [
      { name: CASING_EQUIPMENT, checked: false, visibled: true , isCMTService: false, isCECMTService: true, isCEService: true, isDCEService: true },
      { name: SALES_ORDER_AND_BOM_TYPE, checked: false, visibled: true , isCMTService: false, isCECMTService: false, isCEService: false, isDCEService: true }
    ]
  },
  {
    // control point 3
    type: ControlPointType.ControlPoint3,
    title: 'Job Site Quality Assurance',
    items: [
      ControlPointTabs.JOB_HEADER,
      ControlPointTabs.JOB_SITE_QUALITY,
      ControlPointTabs.EQUIPMENT_FUNCTION,
      ControlPointTabs.JOB_SAFETY_ANALYSIS,
      ControlPointTabs.CONTROL_SPREADSHEET,
    ],
    visibleItems: [
      { name: JSQA, checked: false, visibled: true , isCMTService: true, isCEService: true, isDCEService: false },
      { name: EQUIPMENT_FUNCTION_TEST_DOCUMENT, checked: false, visibled: true , isCMTService: true, isCEService: true, isDCEService: false },
      { name: JSA, checked: false, visibled: true , isCMTService: true, isCEService: true, isDCEService: false },
    ],
    secondaryItems: []
  },
  {
    // control point 4
    type: ControlPointType.ControlPoint4,
    title: 'Reconciliation',
    items: [
        ControlPointTabs.JOB_HEADER,
        ControlPointTabs.ICEM_REALTIME_FILE,
        ControlPointTabs.JOB_LOG,
        ControlPointTabs.PUMP_SCHEDULE,
        ControlPointTabs.POST_JOB_REPORT,
        ControlPointTabs.JOB_SURFACE_EQUIPMENT,
        ControlPointTabs.JOB_OBJECTIVE,
        ControlPointTabs.ADDITIONAL_COMMENTS,

    ],
    visibleItems: [
      { name: ICEM_REAL_TIME_FILE, checked: false, visibled: true , isCMTService: true, isCEService: false, isDCEService: false },
      { name: RECONCILIATION, checked: false, visibled: true , isCMTService: true, isCEService: false, isDCEService: false },
      { name: KPI_DATA, checked: false, visibled: true , isCMTService: true, isCEService: true, isDCEService: false },
      { name: SALES_ORDER_AND_BOM_TYPE, checked: false, visibled: true , isCMTService: true, isCEService: true, isDCEService: true }
    ],
    secondaryItems: [
      { name: CASING_EQUIPMENT, checked: false, visibled: true , isCMTService: false, isCECMTService: true, isCEService: true, isDCEService: true },
    ]
  }
];

export function controlPointListDeepClone() {
  return ControlPointList.reduce((acc, el) => {
    return [
      ...acc,
      {
        ...el,
        items: [...el.items],
        visibleItems: [...el.visibleItems.map(vt => Object.assign({}, vt))]
      }
    ]
  }, [])
}
