import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'libs/environment';
import {
  PumpSchedule,
  CDI,
  Job,
  JobObjective,
  DesignCriteria,
  Contingencies,
  ObjectiveModel,
  CDIModel,
  PumpScheduleStageModel,
  CompatibilityConfirmation,
  JobCasingEquipmentParameters,
  Lesson,
  LoadOutVolumeFluidMaterials,
  CDIValue,
  CDIQuestion
} from 'libs/models';
import { CasingEquipmentAppianSurveyStatus, CasingEquipmentModel, JobCasingEquipmentCP2Model } from 'libs/models/casing-equipment';
import * as moment from 'moment-mini';
import { ControlPointModel, CpPumpScheduleInfo, HandOver, IwellConditionVerificationModel, LocalWellReviewModel } from 'apps/vida/src/modules/control-point/models';
import { ControlPointType, ControlPointList, GetControlPointStatusName, EServiceType, FlagScope3Calculation, ControlPointState } from 'apps/vida/src/modules/shared/constant';
import { HttpService } from './http.service';
import { JobsService, ReportOptions } from './jobs.service';
import { FLUID_TYPE_SCHEDULE } from 'libs/constants';
import { JobCasingEquipment } from 'libs/models/entities/job-casing-equipment';
import { LessonModel } from 'apps/vida/src/modules/control-point/models/lesson.model';
import { JobService } from '.';
import { JobCasingEquipmentCP4Model } from 'libs/models/casing-equipment/job-casing-equipment-cp4';
import { BaGraphData } from 'apps/vida/src/modules/control-point/models/ba-results';
import { JobCasingEquipmentGeneralInfo } from 'libs/models/entities/job-casing-equipment-general';
import { map } from 'rxjs/operators';
import { CarbonFootprintRequest } from 'apps/vida/src/modules/control-point/models/carbonFootprintRequest';
import {GeometryPayload} from '../../../apps/vida/src/modules/wellbore/models/geometry';

export class Practice {
  public checked = false;
  public disabled = false;
  public label: string[]; //A single label, each part of the array is displayed on a new line in the graph.
  public key: string;
  public index: number;

  constructor(label: string[], key: string, index: number) {
    this.label = label;
    this.key = key;
    this.index = index;
  }
}

//added 2 to name to fix unit tests. Not sure why it matters
@Injectable()
export class ControlPointService2 {

  public chartVisibilitySubject = new BehaviorSubject<boolean>(false);
  public updateBaSubject = new Subject();

  public baScore: number;
  public baScenarioScore: number;
  public baGraphData: BaGraphData;

  public practices: Practice[] = [];
  public updatedJobSubject = new BehaviorSubject<any>({});
  public updatedJobCasingEquipment = new BehaviorSubject<any>({});
  public casingEquipmentListChanged = new BehaviorSubject<boolean>(false);
  public isCasingThreadEmptyOnAllTools = new BehaviorSubject<boolean>(false);
  public isCasingSizeEmptyOnAllTools = new BehaviorSubject<boolean>(false);
  public isStagingToolsEmptyOnAllTools = new BehaviorSubject<boolean>(false);
  public updatedcasingEquipmentEditForm = new BehaviorSubject<any>({});
  public jobTvdUpdatedValue = new BehaviorSubject<any>(null);
  public isCasingWeightEmptyOnAllTools = new BehaviorSubject<boolean>(false);
  private _updateSurfaceEquipment$ = new Subject<boolean>();
  private _updateCECompatibilityConfirmations$ = new Subject();
  public _reCaluclateCarbonFootPrint$ = new Subject<boolean>();
  private _totalPumpingHours: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  controlPoint1State$ = new BehaviorSubject(ControlPointState.NonSubmitted);
  controlPoint2State$ = new BehaviorSubject(ControlPointState.NonSubmitted);
  controlPoint1SubmitClicked$ = new BehaviorSubject(false);

  isPumpScheduleReloadAfterCP2Complete = new BehaviorSubject(false);

  public maxPressure$ = new BehaviorSubject<number>(null);

  constructor(
    private http: HttpService,
    private jobService: JobsService,
    private _jobSvc: JobService
  ) { }

  public updateSurfaceEquipment() {
    this._updateSurfaceEquipment$.next(true);
  }

  public get updateSurfaceEquipment$(): Observable<any> {
    return this._updateSurfaceEquipment$.asObservable();
  }

  public updateCECompatibilityConfirmations() {
    this._updateCECompatibilityConfirmations$.next(null);
  }

  public get updateCECompatibilityConfirmations$(): Observable<any> {
    return this._updateCECompatibilityConfirmations$.asObservable();
  }

  public reCaluclateCarbonFootPrint(reload:boolean) {
    this._reCaluclateCarbonFootPrint$.next(reload);
  }

  public get reCaluclateCarbonFootPrint$(): Observable<any> {
    return this._reCaluclateCarbonFootPrint$.asObservable();
  }

  public totalPumpingHours(pumpingHours: number) {
    this._totalPumpingHours.next(pumpingHours);
  }
  public gettotalPumpingHours() {
    return this._totalPumpingHours.asObservable();
  }

  getFirstStageMudWeight(stages: PumpScheduleStageModel[]): number {
    let mudWeight = null;
    const cloneStages = [...stages];
    cloneStages.sort((a, b) => a.order - b.order);

    const firstStage = cloneStages[0];

    if (firstStage.pumpScheduleFluidTypeName === FLUID_TYPE_SCHEDULE.MUD || firstStage.pumpScheduleFluidTypeName === FLUID_TYPE_SCHEDULE.DRILLING_FLUID_MUD) {
      mudWeight = firstStage?.mudParameter?.mudDensity;
    } else {
      mudWeight = firstStage?.slurry?.density;
    }

    if (mudWeight == null){
      mudWeight =0;
    }

    return mudWeight;
  }

  getControlPointJobInfo(jobId: string): Observable<any> {
    return this.http.Get<any>(`${environment.baseUrl}/api/jobs/${jobId}/control-point-job-info`);
  }

  getJobObjectives(jobId: string): Observable<ObjectiveModel> {
    return this.jobService.getJobObjectives(jobId);
  }

  updateJobHeaderInfo(jobId: string, jobHeaderData: any): Observable<boolean> {
    return this.http.Put<boolean>(`${environment.baseUrl}/api/control-point/${jobId}/job-header`, JSON.stringify(jobHeaderData));
  }

  getDesignDocumetUrl(keyword : number) : Observable<{url:string}>{
    return this.http.Get(`${environment.baseUrl}/api/jobs/design-document-url/${keyword}`);
  }

  getTestResultDocumentUrl(testValue) : Observable<{url:string}>{
    return this.http.Get(`${environment.baseUrl}/api/pumpSchedule/test-result-document/${testValue}`);
  }

  updateJobPumpSchedule(jobId: string, pumpSchedule: PumpSchedule[]): Observable<boolean> {
    return this.http.Post(`${environment.baseUrl}/api/pumpSchedule/${jobId}`, JSON.stringify(pumpSchedule));
  }
  calculateEmissionsAll(jobId: string, loadOutVolumeFluidMaterials: LoadOutVolumeFluidMaterials[], status: FlagScope3Calculation = FlagScope3Calculation.Empty): Observable<boolean> {
    const url = `${environment.baseUrl}/api/pumpSchedule/${jobId}/${status}/CalucalateEmissions`;

    return this.http.Post(url, JSON.stringify(loadOutVolumeFluidMaterials));
  }
  calculateCF3PumpSchedule(jobId: string, loadOutVolumeFluidMaterials: LoadOutVolumeFluidMaterials[],isSubmit:boolean, status: FlagScope3Calculation = FlagScope3Calculation.Empty): Observable<boolean> {
    return this.http.Post(`${environment.baseUrl}/api/pumpSchedule/${jobId}/${status}/CalucalateScope3Emissions/${isSubmit}`, JSON.stringify(loadOutVolumeFluidMaterials));
  }

  calculatePumpScheduleScope3Emissions(jobId: string, pumpSchedule: PumpSchedule[], status: FlagScope3Calculation = FlagScope3Calculation.Empty): Observable<PumpSchedule[]> {
    return this.http.Post(`${environment.baseUrl}/api/pumpSchedule/${jobId}/${status}/CaluclatePumpScheduleScope3Emissions`, JSON.stringify(pumpSchedule));
  }

  getPumpSchedulesByJobId(jobId: string) {
    return this.http.Get<any>(`${environment.baseUrl}/api/pumpSchedule/${jobId}`);
  }

  getCasingEquipment(jobId: string, type: number) {
    return this.http.Get<CasingEquipmentModel>(`${environment.baseUrl}/api/casing-equipment/${jobId}?controlPointType=${type}`);
  }

  public getCeJobCasingEquipment(jobId: string): Observable<JobCasingEquipment[]> {

    return this._jobSvc.getJobCasingEquipment(jobId);
  }

  public getJobCasingEquipmentGeneral(jobId: string): Observable<JobCasingEquipmentGeneralInfo> {
    return this.http.Get(`${environment.baseUrl}/api/job-casing-equipment/${jobId}/general`);
  }

  updateCasingEquipment(jobId: string, casingEquipment: CasingEquipmentModel): Observable<boolean> {
    return this.http.Post(`${environment.baseUrl}/api/casing-equipment/${jobId}`, JSON.stringify(casingEquipment));
  }

  updateCP4CasingEquipment(jobId: string, casingEquipment: JobCasingEquipmentCP4Model[]): Observable<boolean> {
    return this.http.Post(`${environment.baseUrl}/api/job-casing-equipment/${jobId}/cp4`, JSON.stringify(casingEquipment));
  }

  updateOneViewEventCP4CasingEquipment(jobId: string, casingEquipment: JobCasingEquipmentCP4Model): Observable<boolean> {
    return this.http.Post(`${environment.baseUrl}/api/job-casing-equipment/${jobId}/cp4/update-oneviewevent`, JSON.stringify(casingEquipment));
  }

  getCP4CasingEquipment(jobId: string): Observable<JobCasingEquipmentCP4Model[]> {
    return this.http.Get(`${environment.baseUrl}/api/job-casing-equipment/${jobId}/cp4`);
  }

  getCP2CasingEquipment(jobId: string): Observable<JobCasingEquipmentCP2Model[]> {
    return this.http.Get(`${environment.baseUrl}/api/job-casing-equipment/${jobId}/cp2`)
          .pipe(map(item => (item as JobCasingEquipmentCP2Model[]).map(i => new JobCasingEquipmentCP2Model(i)) ));
  }

  updateCP2CasingEquipment(jobId: string, casingEquipment: JobCasingEquipmentCP2Model[]): Observable<boolean> {
    return this.http.Post(`${environment.baseUrl}/api/job-casing-equipment/${jobId}/cp2`, JSON.stringify(casingEquipment));
  }

  setJobCasingEquipmentParametersCP1(jobId: string, jobCEParameters: JobCasingEquipmentParameters): Observable<boolean> {
    return this.http.Put(`${environment.baseUrl}/api/job-casing-equipment/${jobId}/ce-parameters/cp1`, JSON.stringify(jobCEParameters));
  }

  getJobCasingEquipmentParameters(jobId: string): Observable<JobCasingEquipmentParameters> {
    return this.http.Get(`${environment.baseUrl}/api/job-casing-equipment/${jobId}/ce-parameters`);
  }

  getJobCasingEquipmentAppianSurveyParameters(jobId: string): Observable<CasingEquipmentAppianSurveyStatus> {
    return this.http.Get(`${environment.baseUrl}/api/job-casing-equipment/${jobId}/appian-status`);
  }

  updateCPCasingEquipmentCompatibilityConfirmation(jobId: string, compatibilityConfirmation: CompatibilityConfirmation): Observable<boolean> {
    return this.http.Put(`${environment.baseUrl}/api/job-casing-equipment/${jobId}/compatibility-confirmation`, JSON.stringify(compatibilityConfirmation));
  }

  updateCPCasingEquipmentIncompatibilitiesHistory(jobId: string, jobCasingEquipmentParameters: JobCasingEquipmentParameters): Observable<boolean> {
    return this.http.Put(`${environment.baseUrl}/api/job-casing-equipment/${jobId}/updatejobincompatibilities`, JSON.stringify(jobCasingEquipmentParameters));
  }

  getCasingEquipmentFailureCategories(): Observable<{ id: number; name: string }> {
    return this.http.Get(`${environment.baseUrl}/api/job-casing-equipment/failure-categories`);
  }

  getWellConditionVerification(controlPointId: string) {
    return this.http.Get<IwellConditionVerificationModel>(`${environment.baseUrl}/api/well-condition/${controlPointId}`);
  }

  updateWellConditionVerification(wellConditionVerification: IwellConditionVerificationModel): Observable<boolean> {
    return this.http.Post(`${environment.baseUrl}/api/well-condition`, JSON.stringify(wellConditionVerification));
  }

  getCDIOverview(jobId: string): Observable<any> {
    return this.http.Get<any>(`${environment.baseUrl}/api/control-point/${jobId}/control-point/cdi-control-point`);
  }

  getCDI(jobId: string): Observable<any> {
    return this.http.Get<any>(`${environment.baseUrl}/api/control-point/${jobId}/control-point/cdi`);
  }

  saveCDI(collection: CDIModel[], jobId: string): Observable<any> {
    return this.http.Put(`${environment.baseUrl}/api/control-point/control-point/cdi/${jobId}`, JSON.stringify(collection));
  }

  saveCDIOveview(cdiData: any, jobId: string): Observable<any> {
    return this.http.Put(`${environment.baseUrl}/api/control-point/control-point/cdi-control-point/${jobId}`, JSON.stringify(cdiData));
  }

  getCDIDetail(jobId: string): Observable<any> {
    return this.http.Get<any>(`${environment.baseUrl}/api/control-point/${jobId}/control-point/cdi-detail`);
  }

  getCDIQuestions(jobId: string): Observable<CDIQuestion[]> {
    return this.http.Get<any>(`${environment.baseUrl}/api/control-point/${jobId}/cdi`);
  }

  updateCDIValue(jobId: string, values: CDIValue[]): Observable<any> {
    return this.http.Put<any>(`${environment.baseUrl}/api/control-point/${jobId}/cdi`, JSON.stringify(values));
  }

  getFluidsList(jobId: string): Observable<any> {
    return this.http.Get<any>(`${environment.baseUrl}/api/jobs/${jobId}/fluids/{true}`);
  }

  saveRheologicalHierarchyCDI(rheologicalHierarchyCDI: CDI, jobId: string): Observable<any> {
    return this.http.Put(`${environment.baseUrl}/api/control-point/control-point/rheological-cdi/${jobId}`, JSON.stringify(rheologicalHierarchyCDI));
  }

  getLocalWellReview(jobId: string): Observable<any> {
    return this.http.Get<any>(`${environment.baseUrl}/api/local-well-review/${jobId}`);
  }

  updateLocalWellReview(jobId: string, localWellReview: LocalWellReviewModel): Observable<any> {
    return this.http.Put<any>(`${environment.baseUrl}/api/local-well-review/${jobId}`, JSON.stringify(localWellReview));
  }

  submitControlPoint(jobId: string, controlPoint: any): Observable<boolean> {
    return this.http.Put(`${environment.baseUrl}/api/control-point/${jobId}/submit`, JSON.stringify(controlPoint));
  }

  unlockControlPoint(jobId: string, controlPoint: any): Observable<boolean> {
    return this.http.Put(`${environment.baseUrl}/api/control-point/${jobId}/unlock`, JSON.stringify(controlPoint));
  }

  getControlPointInfo(jobId: string, type: ControlPointType): Observable<ControlPointModel> {
    return this.http.Get<any>(`${environment.baseUrl}/api/control-point/${jobId}/control-point-type/${type}/info`);
  }

  saveControlPointLessons(jobId: string, lessons: LessonModel[]): Observable<boolean> {
    return this.http.Put(`${environment.baseUrl}/api/control-point/${jobId}/lessons-learned`, JSON.stringify(lessons));
  }

  saveControlPoint(jobId: string, controlPoint: any): Observable<boolean> {
    return this.http.Put(`${environment.baseUrl}/api/control-point/${jobId}/save`, JSON.stringify(controlPoint));
  }

  updateStateControlPoint(jobId: string, controlPoint: any): Observable<boolean> {
    return this.http.Put(`${environment.baseUrl}/api/control-point/${jobId}/update-state-control-point`, JSON.stringify(controlPoint));
  }

  updateKPIStateControlPoint(jobId: string, controlPoint: any): Observable<boolean> {
    return this.http.Put(`${environment.baseUrl}/api/control-point/${jobId}/update-kpi-complete-state`, JSON.stringify(controlPoint));
  }

  getHandOver(controlPointId: string): Observable<HandOver> {
    return this.http.Get<HandOver>(`${environment.baseUrl}/api/control-point-handover/${controlPointId}`);
  }

  saveHandOver(handOver: HandOver): Observable<any> {
    return this.http.Post(`${environment.baseUrl}/api/control-point-handover`, JSON.stringify(handOver));
  }

  getCommunicationTypes(): Observable<any> {
    return this.http.Get<any>(`${environment.baseUrl}/api/control-point-handover/communication-type`);
  }

  completeControlPoint(jobId: string, controlPoint: any, isCeDirectSales: boolean = false): Observable<boolean> {
    return this.http.Put(`${environment.baseUrl}/api/control-point/${jobId}/control-points/complete/${isCeDirectSales}`, JSON.stringify(controlPoint));
  }

  prepareControlPoint(jobId: string, controlPoint: any): Observable<boolean> {
    return this.http.Put(`${environment.baseUrl}/api/control-point/${jobId}/control-points/prepare`, JSON.stringify(controlPoint));
  }

  unCompleteControlPoint(jobId: string, controlPoint: any, isCeDirectSales: boolean = false): Observable<boolean> {
    return this.http.Put(`${environment.baseUrl}/api/control-point/${jobId}/control-points/un-complete/${isCeDirectSales}`, JSON.stringify(controlPoint));
  }

  saveJobObjective(jobId: string, jobObjectives: JobObjective[], jobCriterias: DesignCriteria[], jobContingencies: Contingencies[]): Observable<boolean> {
    return this.http.Put(`${environment.baseUrl}/api/control-point/${jobId}/update-job-objective`, JSON.stringify({ jobObjectives, jobCriterias, jobContingencies }));
  }

  saveUsingEquipment(jobId: string, isUsingEquipment: boolean): Observable<boolean> {
    return this.http.Put(`${environment.baseUrl}/api/jobs/${jobId}/update-using-equipment`, JSON.stringify(isUsingEquipment));
  }

  saveJobLog(jobId: string, model): Observable<boolean> {
    return this.http.Post(`${environment.baseUrl}/api/joblog/update/${jobId}/manual`, JSON.stringify(model));
  }

  saveCriticalWellReview(jobId: string, criticalWellReview): Observable<boolean> {
    return this.http.Put(`${environment.baseUrl}/api/control-point/${jobId}/control-points/critical-well-review`, JSON.stringify(criticalWellReview));
  }

  getAppianCp2Link(jobCode: string): Observable<any> {
    return this.http.Get(`${environment.baseUrl}/api/jobs/${jobCode}/cp2-link`).pipe(map((x: { link: string }) => x.link));
  }

  updateCasingEquipmentCompletion(jobId: string, ceCompletionModel: any): Observable<any> {
    return this.http.Put(`${environment.baseUrl}/api/control-point/${jobId}/ce-completion`, JSON.stringify(ceCompletionModel));
  }

  getCarbonFootprint(jobId: string) {
    return this.http.Get(`${environment.baseUrl}/api/carbonFootPrint/${jobId}`);
  }

  hasScope3Access(groupId: string): Observable<boolean> {
    return this.http.Get(`${environment.baseUrl}/api/carbonFootPrint/access/${groupId}`)
  }

  computeCarbonFootprint(jobId: string, request: CarbonFootprintRequest, isSubmit = false, isApprove = false, logScope3Emissions=false) {
    const approvedQuery = isApprove ? `?isApprove=true` : ``;
    return this.http.Post(`${environment.baseUrl}/api/carbonFootPrint/compute/${jobId}/${isSubmit}/${logScope3Emissions}${approvedQuery}`, request);
  }

  //should sections be removed from report? (USER STORY 424504)
  shouldReduce(job: Job) {
    return !job.cmtSvc && (job.ceSvc || job.ceDirectSales);
  }

  generateControlPoint(jobId: string, options: ReportOptions) {
    return this.jobService.getSpreadSheet(jobId, options);
  }

  generateLoadSheetCalculator(jobId: string) {
    return this.jobService.getLoadSheetCalculator(jobId);
  }

  public callSheetDownload(url: string, fileName: string) {
    this.jobService.callSheetDownload(url, fileName);
  }

  materialLoadSheetDownload(url: string, fileName: string) {
    this.jobService.materialLoadSheetDownload(url, fileName);
  }

  getJobType(job: Job): EServiceType {
    // old behavior without ceCmtService
    // return (job.ceSvc && job.cmtSvc) ? 'cmtService' :  job.ceSvc ? 'ceService' : job.cmtSvc ? 'cmtService' : 'dceService';

    if (job.ceSvc && job.cmtSvc) {
      return EServiceType.CeCmtService;
    }

    if (job.ceSvc) {
      return EServiceType.CeService;
    }

    if (job.cmtSvc) {
      return EServiceType.CmtService
    }

    return EServiceType.DceService;
  }

  getLatestStatusCp(controlPoints, jobId, serviceType?: EServiceType) {

    const defaultDate = '1970-01-01';
    return controlPoints.map(cp => {
      const {
        submittedBy,
        rejectedBy,
        approvedBy,
        unlockedBy,
        unlockDate,
        completedBy,
        completedDate,
        submittedDate,
        approvedDate,
        rejectedDate,
        unCompletedBy,
        unCompletedDate,
        preparedBy,
        preparedDate,
        type
      } = cp;
      const cpMasterData = ControlPointList.find(x => x.type === type);
      cp.title = cpMasterData.title;
      cp.items = cpMasterData.items;
      switch (serviceType) {
        case EServiceType.CeCmtService:
          cp.visibleItems = cpMasterData.visibleItems.filter(vi => vi.isCMTService === true);
          cp.secondaryItems = cpMasterData.secondaryItems?.filter(x => x.isCECMTService === true);
          break;
        case EServiceType.CmtService:
          cp.visibleItems = cpMasterData.visibleItems.filter(vi => vi.isCMTService === true);
          cp.secondaryItems = cpMasterData.secondaryItems?.filter(x => x.isCMTService === true);
          break;
        case EServiceType.CeService:
          cp.visibleItems = cpMasterData.visibleItems.filter(vi => vi.isCEService === true);
          cp.secondaryItems = cpMasterData.secondaryItems?.filter(x => x.isCEService === true);
          break;
        case EServiceType.DceService:
          cp.visibleItems = cpMasterData.visibleItems.filter(vi => vi.isDCEService === true);
          cp.secondaryItems = cpMasterData.secondaryItems?.filter(x => x.isDCEService === true);
          break;
        default:
          cp.visibleItems = cpMasterData.visibleItems;
          cp.secondaryItems = cpMasterData.secondaryItems;
          break;
      }
      cp.cpLink = [`/control-point/cp-detail/${type}/${jobId}`];
      cp.statusText = GetControlPointStatusName(cp.eControlPointState);
      const list = [
        {
          owner: submittedBy,
          createdDate: submittedDate || defaultDate,
          type: 'Submitted'
        },
        {
          owner: rejectedBy,
          createdDate: rejectedDate || defaultDate,
          type: 'Rejected'
        },
        {
          owner: approvedBy,
          createdDate: approvedDate || defaultDate,
          type: 'Approved'
        },
        {
          owner: unlockedBy,
          createdDate: unlockDate || defaultDate,
          type: 'Unlocked'
        },
        {
          owner: completedBy,
          createdDate: completedDate || defaultDate,
          type: 'Completed'
        },
        {
          owner: unCompletedBy,
          createdDate: unCompletedDate || defaultDate,
          type: 'Uncompleted'
        },
        {
          owner: preparedBy,
          createdDate: preparedDate || defaultDate,
          type: 'Prepared'
        }
      ];
      const sorted = list.sort((a, b) => (moment(a.createdDate).isBefore(moment(b.createdDate)) ? -1 : 1));
      cp.lastest = sorted.pop();
      return cp;
    });
  }

  public checkPumpScheduleInfo(jobId: string): Observable<CpPumpScheduleInfo> {

    return this.http.Get<CpPumpScheduleInfo>(`${environment.baseUrl}/api/control-point/${jobId}/check-pump-schedule-info`);
  }

  public checkReconciliation(jobId: string): Observable<boolean> {

    return this.http.Get<boolean>(`${environment.baseUrl}/api/control-point/${jobId}/check-reconcilation`);
  }

  public fileNameFormatter(fileName: string) {
    return fileName
      .split('').map(c => c.replace(/([/?|:\\"<*>])/ig, '')).join('').trim() // Remove special characters
  }

  public getLessons(jobId: string): Observable<Lesson[]> {
    return this.http.Get<Lesson[]>(`${environment.baseUrl}/api/control-point/${jobId}/lessons`);
  }

  /**
   * Method to update Wellbore Only from CP
   */
  public updateWellboreGeometry(jobId: string, payload: GeometryPayload) {
    return this.http.Put<boolean>(`${environment.baseUrl}/api/wellbore/${jobId}`, JSON.stringify(payload));
  }

  public getCPFileName(job:Job): string {
    const vIDAJobId = job.jobCode;
    const customerName = job.customerName.trim().toUpperCase();
    const jobName = job.jobName.trim().toUpperCase();
    const fileNameFormatted = this.fileNameFormatter(`CP3 (${vIDAJobId})-${customerName}-${jobName} ${environment.name}`);
    return fileNameFormatted;
  }
}
